
import { defineComponent, onMounted, ref } from "vue";
import { Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import base from "@/api/base.js";
import ElConfigProvider from "element-plus";
import ko from "element-plus/lib/locale/lang/ko";

export default defineComponent({
  name: "schedule-detail",
  components: {
    Field,
    Form,
    ElConfigProvider,
  },

  data() {
    return {
      detailSchedule: {
        brand: {},
        company: {},
        store: {},
        inspector: {},
        check_version: {},
        latest_result: {},
      },
      users: [],
      disabledDate(time) {
        const nowDate = new Date();
        const yesterDate = nowDate.getTime() - 1 * 24 * 60 * 60 * 1000;
        return yesterDate > time.getTime();
      },
      checkitemversions: [],
      sessionId: "",
      ko,
    };
  },
  // props: { id: String },

  mounted() {
    this.sessionId = sessionStorage.getItem("id");
    this.getData();
    base
      .getUserList()
      .then((res) => {
        console.log(res);
        this.users = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    getData() {
      base
        .getSchedule(this.sessionId)
        .then((res) => {
          console.log(res);
          this.detailSchedule = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
      base
        .getCheckVersionList()
        .then((res) => {
          console.log(res);
          this.checkitemversions = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editData() {
      const edit = {
        id: this.detailSchedule.id,
        check_date: this.detailSchedule.check_date,
        check_ver_ty: this.detailSchedule.check_ver_ty,
        inspector: this.detailSchedule.inspector.id,
      };
      console.log(edit);
      base
        .updateSchedule(edit)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            alert("점검 일정 정보를 수정했습니다.");
            this.$router.push("/schedules");
          } else {
            alert(res.status);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },
  },
});
